<template>
  <el-container class="box">
    <!-- 头部 -->
    <HeaderSecond></HeaderSecond>


    <!--  中部  -->
    <el-container class="center_container">

      <!--   主要内容   -->
      <el-main class="main">
        <el-row class="main_row">

          <!--   个人信息   -->
          <el-col class="personal_box">
            <div class="main_box_col">
              <div class="personal_title">个人信息</div>
              <div class="personal_show_box">
                <div class="personal_show_header">
                  <img :src="img" v-if="user_information.headUrl == '' || user_information.headUrl == null"
                       class="personal_show_img" alt="图片加载失败">
                  <img :src="user_information.headUrl"
                       v-if="user_information.headUrl != ''  && user_information.headUrl != null"
                       class="personal_show_img" alt="图片加载失败"><router-link to="/personalModify" class="personal_show_btn">
                    <buton>修改个人信息</buton>
                  </router-link>
                </div>
                <div class="personal_show_content">
                  <ul class="personal_show_content_ul">
                    <li class="personal_show_content_li">
                      <span>姓名:</span>
                      <span>{{user_information.name}}</span>
                    </li>
                    <li class="personal_show_content_li">
                      <span>性别:</span>
                      <span v-show="user_information.sex == 0">男</span>
                      <span v-show="user_information.sex == 1">女</span>
                      <span v-show="user_information.sex == 2">其他</span>
                    </li>
                    <li class="personal_show_content_li">
                      <span>系部:</span>
                      <span>{{user_information.department}}</span>
                    </li>
                    <li class="personal_show_content_li">
                      <span>专业:</span>
                      <span>{{user_information.major}}</span>
                    </li>
                    <li class="personal_show_content_li">
                      <span>年级:</span>
                      <span>{{user_information.grade}}</span>
                    </li>
                    <li class="personal_show_content_li">
                      <span>班级:</span>
                      <span>{{user_information.classroom}}</span>
                    </li>
                    <li class="personal_show_content_li">
                      <span>出生日期:</span>
                      <span>{{user_information.birthday}}</span>
                    </li>
                    <li class="personal_show_content_li">
                      <span>入校时间:</span>
                      <span>{{user_information.entranceTime}}</span>
                    </li>
                    <li class="personal_show_content_li">
                      <span>个人邮箱:</span>
                      <span>{{user_information.email}}</span>
                    </li>
                    <li class="personal_show_content_li">
                      <span>个人手机:</span>
                      <span>{{user_information.phone}}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </el-col>

          <!--   预约记录   -->
          <el-col class="reservation_history_box">
            <div class="grid-content ep-bg-purple main_box_col main_box_seat_history">
              <div class="main_seat_history_title">预约记录</div>
              <div class="main_seat_history">
                <table class="main_seat_history_tb">
                  <thead class="main_seat_history_thead">
                  <tr>
                    <th class="main_seat_history_th">资源名称</th>
                    <th class="main_seat_history_th">预约时间</th>
                    <th class="main_seat_history_th">资源类型</th>
                    <th class="main_seat_history_th">教室位置</th>
                    <th class="main_seat_history_th">教室号</th>
                  </tr>
                  </thead>
                  <tbody class="main_seat_history_tbody">
                  <tr v-for="i in historyReservation_list">
                    <th class="main_seat_history_th">{{ i.seatName }}</th>
                    <th class="main_seat_history_th">{{ i.appointmentSlotsName }}</th>
                    <th class="main_seat_history_th">{{ i.placeName }}</th>
                    <th class="main_seat_history_th">{{ i.startTime }}&nbsp;~&nbsp;{{i.endTime}}</th>

                    <th class="main_seat_history_th" v-if="i.status == 0">已取消</th>
                    <th class="main_seat_history_th" v-if="i.status == 1">已预约</th>
                    <th class="main_seat_history_th" v-if="i.status == 2">已签到</th>
                    <th class="main_seat_history_th" v-if="i.status == 3">已签退</th>
                    <th class="main_seat_history_th" v-if="i.status == 4">已失效</th>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </el-col>
        </el-row>
      </el-main>

      <!--   侧边栏   -->
      <el-aside class="aside">
        <el-row class="aside_box_row">
          <!--   积分中心   -->
          <el-col class="integral_box_col">
            <div class="integral_box">
              <div class="integral_title">积分中心</div>
              <div class="integral_content">
                <span class="integral_content_part">
                  <span>积分总计</span>
                  <span>{{user_information.creditScore}}</span>
                </span>
<!--                <hr>-->
<!--                <span class="integral_content_part">-->
<!--                  <span>今日积分</span>-->
<!--                  <span>0</span></span>-->
              </div>
            </div>
          </el-col>

          <!--   当前预约   -->
          <el-col class="reservation_box_col">
            <span class="reservation_content_part">
               <span>预约记录</span>
               <span>{{total}}</span>
            </span>
            <hr>
            <span class="reservation_content_part">
               <span>当前预约</span>
               <span>{{reCount}}</span>
            </span>
          </el-col>
        </el-row>
      </el-aside>
    </el-container>
  </el-container>
</template>

<script>

import HeaderSecond from "@/components/HeaderSecond.vue";
import User_api from "@/request/user";


export default {
  name: 'Personal',
  components: {HeaderSecond},
  computed: {
    reCount() {
      return this.historyReservation_list.reduce((count, item) => {
        return count + (item.status === 1 ? 1 : 0);
      }, 0);
    },
  },
  data() {
    return {
      img: require("../assets/person.png"),
      userid:localStorage.getItem('userId'),
      user_information:{},
      // 获取预约历史记录
      page_historyReservation: 1,
      size_historyReservation: 5,
      // 预约历史记录
      total:0,
      historyReservation_list: [],
    };
  },
  methods: {
    // 获取用户信息
    get_User(){
      User_api.FindUser(this.userid).then((res)=>{
        console.log(res)

        this.user_information = res.data
      }).catch((rep)=>{
        console.log(rep)
      })
    },

    // 获取预约历史记录
    get_reservation_history() {
      User_api.listReservationUser(this.page_historyReservation, this.size_historyReservation, this.userid)
          .then((res) => {
            this.total = res.data.total
            this.historyReservation_list = res.data.records
          })
          .catch((rep) => {
            console.log(rep)
          })
    }
  },
  mounted() {
    this.get_User();
    this.get_reservation_history();
  }
}
</script>

<style scoped>
* {
  marigin: 0;
  padding: 0;
}

.box {
  height: 100vh;
  display: flex;
  flex-direction: column;
}


/*中部*/
.center_container {
  background-color: rgb(229, 233, 240);
  height: 100%;
  marigin: 0;
  padding: 0;
  overflow: auto;
}


/*主要内容*/
.main {
  min-width: 700px;
  overflow: hidden;
}

.main_row {
  display: flex;
  flex-direction: column;
}

/*个人信息*/
.personal_box {
  /*min-width: 600px;*/
  background-color: #fff;
  padding: 20px;
  margin-top: 10px;
  font-weight: bold;
}

.personal_title {
  height: 30px;
  font-weight: bold;
  text-align: start;
  margin-bottom: 5px;
}

.personal_show_box {
  display: flex;
  flex-direction: row;
}

.personal_show_header {
  display: flex;
  flex-direction: column;
}

.personal_show_img {
  background-color: #808080;
  padding: 10px;
  width: 90px;
  height: 90px;
}

.personal_show_btn {
  margin-top: 10px;
  background-color: rgb(129, 129, 129);
  padding: 10px;
  font-size: 15px;
  color: #fff;
}

.personal_show_btn:hover {
  text-decoration: none;
}

.personal_show_btn:active {
  text-decoration: none;
}

.personal_show_btn:visited {
  text-decoration: none;
}

.personal_show_btn:link {
  text-decoration: none;
}

.personal_show_content {
  width: 100%;
}

.personal_show_content_ul {
  width: 100%;
  list-style: none;
  display: grid;
  grid-template-columns:repeat(2, 1fr);
  grid-row-gap: 10px;
  grid-column-gap: 10px;
  margin-left: 10px;
}

.personal_show_content_li {
  /*background-color: #a6c1ee;*/
  padding: 5px;
  text-align: start;
}


/*预约记录*/
.reservation_history_box {
  /*min-width: 600px;*/
  background-color: #fff;
  padding: 20px;
  margin-top: 30px;
}

.main_box_seat_history {
}

.main_seat_history_title {
  height: 30px;
  font-weight: bold;
  text-align: start;
  margin-bottom: 5px;
}

.main_seat_history_tb {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}

.main_seat_history_thead {
  background-color: rgb(244, 244, 244);
}

.main_seat_history_tbody {
  background-color: #fff;
}

.main_seat_history_th {
  line-height: 5vh;
  text-align: center;
}


/*侧边栏*/
.aside {
  /*background-color: #a6c1ee;*/
}

.aside_box_row {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.integral_box_col {
  width: 100%;
  background-color: #fff;
  padding: 20px;
  margin-top: 10px;
}

.integral_title {
  height: 30px;
  font-weight: bold;
  text-align: start;
  margin-bottom: 5px;
}

.integral_content {
  width: 100%;
  background-color: rgb(129, 129, 129);
  display: flex;
  flex-direction: row;
}

.integral_content_part {
  width: 100%;
  text-align: center;
  padding: 10px;
  color: #fff;
  font-weight: bold;
  font-size: 12px;
  display: flex;
  flex-direction: column;
}

.reservation_box_col {
  width: 100%;
  background-color: #fff;
  padding: 20px;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
}

.reservation_content_part {
  width: 100%;
  text-align: center;
  padding: 10px;
  color: #000;
  font-weight: bold;
  font-size: 12px;
  display: flex;
  flex-direction: column;
}


</style>