import service from "@/request/axios";

const url = '/api/v1/creditScoreRecord/';

// 将所有的请求方法封装到一个对象中
const Points_api = {
    // 增加积分记录
    SavePoints: (json) => service.post(`${url}`,json),

    // 分页查询
    ListPoints: (page,size,keyword) => service.get(`${url}list/${page}/${size}?keyword=${keyword}`),

    // 根据用户id查询分页
    ListPointsById: (page,size,userId) => service.get(`${url}listByUserId/${page}/${size}?userId=${userId}`),

    // 查找积分记录
    FindPoints: (id) => service.get(`${url}${id}`),

    // 修改积分记录
    UpdatePoints: (json) => service.put(`${url}`, json),

    // 删除积分记录
    DeletePoint: (id) => service.delete(`${url}`, { data: [ id ] }),

    // 删除积分记录组
    DeletePoints: (ids) => service.delete(`${url}`, { data:  ids  })

};

export default Points_api