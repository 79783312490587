import service from "@/request/axios";

// 定义预约时间管理 API
const url = '/api/v1/appointment-slots/';
// 将所有的请求方法封装到一个对象中
const Appointment_slots_api = {
    // 预约时间列表
    ListAppointment_slots: (keyword,page,size) => service.get(`${url}list/${page}/${size}`, { params: { keyword } }),

};

// 导出对象
export default Appointment_slots_api;