import service from "@/request/axios";

// main

// 定义用户管理 API
const url = '/api/v1/announcement/';
// 将所有的请求方法封装到一个对象中
const Announcement_api = {
    // 座位列表
    ListAnnouncement: (page,size) => service.get(`${url}list/${page}/${size}`),


};

// 导出对象
export default Announcement_api;