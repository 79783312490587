import { createApp } from 'vue'
import { createStore } from 'vuex'

const store = createStore({
    state: {
        // 储存token和userId
        Authorization: localStorage.getItem("Authorization") || "", // 简化三元表达式
        userId: localStorage.getItem("userId") || "", // 新增存储userId
        isLogin: localStorage.getItem("isLogin")
    },
    mutations: {
        // 修改token和userId，并将它们存入localStorage
        changeLogin(state, { Authorization, userId, isLogin }) {
            state.Authorization = Authorization;
            state.userId = userId;
            state.isLogin = isLogin
            // 分别保存token和userId到localStorage
            localStorage.setItem("Authorization", Authorization);
            localStorage.setItem("userId", userId);
            localStorage.setItem("isLogin", isLogin)
        }
    },
    getters: {
        // 定义一个getter来获取userId
        getUserId: state => {
            return state.userId; // 返回state中的userId
        },
        // 定义一个getter获取isLogin
        getIsLogin: state => {
            return state.isLogin;
        }
    }
})



export default store