<template>
  <router-view/>
  <footer class="app_footer">
    <a href="https://beian.miit.gov.cn/" style="color: black;text-decoration: none">备案信息:&nbsp;粤ICP备2024277118号</a>
  </footer>
</template>

<style>
*{
  padding: 0px;
  margin: 0px;
}
.app_footer{
  display: flex;
  justify-content: start;
  padding: 20px;
}
</style>
