import axios from "axios";
import jsonBig from "json-bigint"

// 创建axios实例
const service = axios.create({
    baseURL: "http://101.35.211.205:6066/", // api的base_url
    timeout: 5000, // 请求超时时间
    headers: {
        // 设置请求头
        'Content-Type': 'application/json'
    },
    transformResponse: [function (data) {//transformResponse这个配置项可以拦截接口返回的内容进行处理
        try {
            // 如果大数字类型转换成功则返回转换的数据结果
            // return JSON.stringify(data.data.records.id);
            return jsonBig.parse(data);
        } catch (err) {
            // 如果转换失败，代表没有长数字可转，正常解析并返回
            return JSON.parse(data)
        }
    }]

});

// 请求拦截器
service.interceptors.request.use(
    config => {
        // 可以在这里添加请求头等信息
        if (localStorage.getItem("Authorization")) {
            config.headers.Authorization = localStorage.getItem("Authorization")
        }
        return config;
    },
    err => {
        // 请求错误处理
        const {status} = err.response;
        if (status === 401) {
            localStorage.removeItem('Authorization');
            this.$router.push('/login');
        }
        console.log(err); // for debug
        return Promise.reject(err);
    }
);

// 响应拦截器
service.interceptors.response.use(
    res => {
        // const i = res.data.data.records[0].id.toString()
        // 如果返回的状态码为200，说明成功，可以直接返回数据

        if (res.data.code === '200') {
            return Promise.resolve(res.data);
        } else {
            return Promise.reject(res.data);
        }
    },
    err => {
        switch (err.code) {
            case 401:
                console.log('401');
                break;
            case 403:
                console.log('403');
                break;
            case 404:
                console.log('404');
                break;
            default:
                console.log('无法发送请求'); // 输出错误信息
        }
        return Promise.reject(err.response);
    }
);

export default service;