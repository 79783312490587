import service from "@/request/axios";

// main

// 定义用户管理 API
const url = '/api/v1/auth/';
// 将所有的请求方法封装到一个对象中
const Auth_api = {
    // 座位列表
    login: (json) => service.post(`${url}login`,json),


};

// 导出对象
export default Auth_api;