<template>
  <el-container class="box">
    <!-- 头部 -->
    <HeaderSecond></HeaderSecond>

    <!--  中部  -->
    <el-container class="center_container">

      <!--   侧边栏   -->
      <el-aside class="aside">
        <el-row class="tac">
          <el-col>
            <el-menu
                active-text-color="#ffd04b"
                background-color="#545c64"
                class="el-menu-vertical-demo"
                :default-active="String(place_id)"
                text-color="#fff">
              <el-menu-item :index="String(i.id)" v-for="i in place_list" @click="place_btn_click(i.id)">
                <el-icon>
                  <icon-menu/>
                </el-icon>
                <span>{{ i.placeName }}</span>
              </el-menu-item>
            </el-menu>
          </el-col>
        </el-row>
      </el-aside>

      <!--   主要内容   -->
      <el-main class="main">
        <el-row :gutter="20" class="main_box">

          <!--    可视化视图      -->
          <el-col :span="15">
            <div class="grid-content ep-bg-purple main_box_col main_box_seat_show">
              <div class="main_seat_show_title">
                当前座位
              </div>

              <div clsss="main_seat_show" v-if="place_class=='1'">
                <ul class="main_seat_show_ul">
                  <li v-for="i in seat_list" class="main_seat_show_li"
                      :class="{
                    'main_seat_show_li_on':i.user_use==1,
                    'main_seat_show_li_off':i.user_use==2,
                    'main_seat_show_li_empty':i.isAvailable==0,
                    'main_seat_show_li_click':i.id==seat_click,
                  }"
                      @click="seat_btn_click(i.id,i.seatLayout)">
                    <span v-show="i.isAvailable != 0" class="main_seat_show_li_txt">
                      {{ i.isAvailable }}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </el-col>

          <!--     时间选择     -->
          <el-col :span="9">
            <div class="grid-content ep-bg-purple main_box_col main_box_seat_control">
              <div class="main_seat_contorl_title">
                时间选择
              </div>
              <div class="main_seat_control">
                <div style="display: flex;overflow-x: auto">
                  <ul class="main_seat_control_time">
                    <li class="main_seat_control_time_li"
                        :class="{
                        'main_seat_control_time_li_click':i.id == seat_time_click
                      }"
                        v-for="i in appointmentSlot_list"
                        @click="time_btn_click(i.id)">
                      <div class="main_seat_control_time_li_title">{{ i.startTime }}</div>
                      <div class="main_seat_control_time_li_title">~</div>
                      <div class="main_seat_control_time_li_title">{{ i.endTime }}</div>
                    </li>
                  </ul>
                </div>

              </div>

              <div class="main_seat_control_time_li_main">
                <span class="main_seat_control_time_li_state">空闲</span>
                <el-button type="info" class="main_seat_control_time_li_btn btn_p" @click="send_Reservation">预约</el-button>
              </div>
            </div>
          </el-col>
        </el-row>

        <!--     预约记录     -->
        <el-row :gutter="20" class="main_box">
          <el-col :span="24">
            <div class="grid-content ep-bg-purple main_box_col main_box_seat_history">
              <div class="main_seat_history_title">预约记录</div>
              <div class="main_seat_history">
                <table class="main_seat_history_tb">
                  <thead class="main_seat_history_thead">
                  <tr>
                    <th class="main_seat_history_th">座位名称</th>
                    <th class="main_seat_history_th">场所名称</th>
                    <th class="main_seat_history_th">场次名称</th>
                    <th class="main_seat_history_th">预约时间</th>
                    <th class="main_seat_history_th">创建时间</th>
                    <th class="main_seat_history_th">状态</th>
                  </tr>
                  </thead>
                  <tbody class="main_seat_history_tbody">
                  <tr v-for="i in historyReservation_list">
                    <th class="main_seat_history_th">{{ i.seatName }}</th>
                    <th class="main_seat_history_th">{{ i.appointmentSlotsName }}</th>
                    <th class="main_seat_history_th">{{ i.placeName }}</th>
                    <th class="main_seat_history_th">{{ i.startTime }}&nbsp;~&nbsp;{{i.endTime}}</th>
                    <th class="main_seat_history_th">{{ i.createTime}}</th>

                    <th class="main_seat_history_th" v-if="i.status == 0">已取消</th>
                    <th class="main_seat_history_th" v-if="i.status == 1">
                      <el-button type="info" class="main_seat_control_time_li_btn2 btn_p" @click="send_Code(i.id)">签到</el-button>
                      <el-button type="info" class="main_seat_control_time_li_btn2 btn_p" style="margin-left: 10px" @click="send_ReservationCancel(i.id)">取消预约</el-button>
                    </th>
                    <th class="main_seat_history_th" v-if="i.status == 2">
                      <el-button type="info" class="main_seat_control_time_li_btn2 btn_p" @click="send_Code(i.id)">签退</el-button>
                    </th>
                    <th class="main_seat_history_th" v-if="i.status == 3">已签退</th>
                    <th class="main_seat_history_th" v-if="i.status == 4">已失效</th>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </el-col>
        </el-row>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>

import Seat_api from "@/request/seat"
import Place_api from "@/request/place"
import Appointment_slots_api from "@/request/appointment_slots";
import Reservation_api from "@/request/reservation";
import HeaderSecond from "@/components/HeaderSecond.vue";
import User_api from "@/request/user";
import router from "@/router";

export default {
  name: 'Seat',
  components: {HeaderSecond},
  data() {
    return {
      // 获取教室数据
      keyword_place: null,
      page_place: 1,
      size_place: 100,
      // 教室
      place_list: [],
      place_class: 1, // 教室布局
      place_id: 3,
      // 获取座位数据
      page_seat: 1,
      size_seat: 64,
      // 座位
      seat_list: [],
      seat_click: 0, // 座位选择
      // 获取预约时间数据
      page_appointmentSlot: 1,
      size_appointmentSlot: 100,
      // 预约时间
      appointmentSlot_list: [],
      seat_time_click: 0, // 时间段选择
      // 获取预约信息数据
      page_reservation: 1,
      size_reservation: 64,
      // 预约信息
      reservation_list: [],
      // 用户信息
      uid: localStorage.getItem('userId'),
      // 预约功能
      Reservation:{},
      // 获取预约历史记录
      page_historyReservation: 1,
      size_historyReservation: 5,
      // 预约历史记录
      historyReservation_list: [],
    };
  },
  methods: {

    // 获取教室数据
    get_place_list() {
      Place_api.ListPlace(this.keyword_place, this.page_place, this.size_place).then((res) => {
        this.place_list = res.data.records
        this.place_list = this.place_list.sort(function (a, b) {
          return a.id - b.id;
        });

        this.place_btn_click(this.place_list[0].id, this.place_list[0].seatLayout)

      }).catch((rep) => {
        console.log(rep)
      })
    },

    // 教室点击
    place_btn_click(id, seatLayout) {
      if (seatLayout == '' || seatLayout == null) {
        seatLayout = '1'
      }

      this.place_id = id;
      this.place_class = seatLayout;
      this.get_seat_list();
      this.get_AppointmentSlots_list();
    },


    // 获取座位数据
    get_seat_list() {
      Seat_api.ListSeat(this.place_id, this.page_seat, this.size_seat).then((res) => {

        this.seat_list = res.data.records
        this.seat_list = this.seat_list.sort(function (a, b) {
          return a.seatNo - b.seatNo;
        });


      }).catch((rep) => {
        console.log(rep)
      })
    },

    // 座位点击
    seat_btn_click(id) {
      this.seat_click = id;
    },


    // 获取预约时间数据
    get_AppointmentSlots_list() {
      Appointment_slots_api.ListAppointment_slots(this.place_id, this.page_appointmentSlot, this.size_appointmentSlot).then((res) => {
        this.appointmentSlot_list = res.data.records
        this.appointmentSlot_list = this.appointmentSlot_list.sort(function (a, b) {
          return a.id - b.id;
        });

        if (this.appointmentSlot_list[0].id != 0 || this.appointmentSlot_list[0].id != null) {
          this.time_btn_click(this.appointmentSlot_list[0].id)
        }

      }).catch((rep) => {
        console.log(rep)
      })
    },

    // 时间段点击
    time_btn_click(id) {
      this.seat_time_click = id;
      this.get_reservation_list()
    },

    // 获取预约信息数据
    get_reservation_list() {
      Reservation_api.ListReservation(this.place_id, this.seat_time_click, this.page_reservation, this.size_reservation).then((res) => {
        this.reservation_list = res.data.records
        this.reservation_list = this.reservation_list.sort(function (a, b) {
          return a.id - b.id;
        });


        for (let i = 0; i < this.seat_list.length; i++) {
          this.seat_list[i]['user_use'] = 0
          for (let d = 0; d < this.reservation_list.length; d++) {

            if (this.seat_list[i].id == this.reservation_list[d].seatId) {
              if (this.uid == this.reservation_list[d].userId) {
                this.seat_list[i]['user_use'] = 1
              } else {
                this.seat_list[i]['user_use'] = 2
              }
            }
          }

        }


      }).catch((rep) => {
        console.log(rep)
      })
    },


    // 预约
    send_Reservation(){
      this.Reservation = {
        "appointmentSlotsId": this.seat_time_click,
        "placeId": this.place_id,
        "seatId": this.seat_click,
        "userId": this.uid
      }

      Reservation_api.Reserve(this.Reservation).then((res)=>{
        this.get_place_list();
        this.get_reservation_history();
      }).catch((rep)=>{
        alert(rep.message)
      })
    },

    // 取消预约
    send_ReservationCancel(id){
      Reservation_api.ReserveCancel(id).then((res)=>{
        this.get_place_list();
        this.get_reservation_history();
      }).catch((rep)=>{
        console.log(rep);
      })
    },

    // 跳转二维码签到
    send_Code(reservationId){
      router.push({path:'/userCode',query: {reservationId:reservationId}})
    },

    // 获取预约历史记录
    get_reservation_history() {
      User_api.listReservationUser(this.page_historyReservation, this.size_historyReservation, this.uid)
          .then((res) => {
            this.historyReservation_list = res.data.records
          })
          .catch((rep) => {
            console.log(rep)
          })
    },


  },
  mounted() {
    this.get_place_list();
    this.get_reservation_history();
  }
}
</script>

<style scoped>
* {
  marigin: 0;
  padding: 0;
}

.box {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.btn_p{
  padding-left: 10px;
  padding-right: 10px;
}

/*中部*/
.center_container {
  overflow: hidden;
  height: 100%;
  overflow: auto;
  marigin: 0;
  padding: 0;
}

/*侧边栏*/
.aside {
  overflow-y: auto;
  background-color: #545c64;
  marigin: 0;
  padding: 0;
}

/*主要内容*/
.main {
  background-color: rgb(229, 233, 240);
  min-width: 900px;
}

.main_box {
  margin-bottom: 10px;
}

.main_box_col {
  background-color: #fff;
  margin: 10px;
  padding: 30px;
  overflow: hidden;
}

/*座位展示*/
.main_box_seat_show {
  height: 300px;
}

.main_seat_show_title {
  height: 30px;
  font-weight: bold;
  margin-bottom: 5px;
}


.main_seat_show_ul {
  list-style: none;
  display: grid;
  grid-template-columns:repeat(8, 1fr);
  grid-row-gap: 6px;
  align-content: center;
  justify-content: center;
}

.main_seat_show_li {
  background-color: #7c7c7c;
  padding: 5px;
  width: 20px;
  height: 20px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  color: #fff;
  margin: auto;
  transition: 0.1s;
  /*margin-bottom: 6px;*/
}

.main_seat_show_li_txt {
  display: flex;
  justify-content: center;
  align-items: center;
}

.main_seat_show_li_on {
  background-color: #2c8c0c;
}

.main_seat_show_li_off {
  background-color: #a81313;
}

.main_seat_show_li_empty {
  background-color: #ffffff;
  pointer-events: none;
}

.main_seat_show_li_click {
  outline: #ffb800 4px solid;
}

/*时间选择*/
.main_box_seat_control {
  /*overflow: auto;*/
  height: 300px;
}

.main_seat_contorl_title {
  height: 30px;
  font-weight: bold;
  margin-bottom: 5px;
}

.main_seat_control {
  height: 220px;
  width: 100%;
  /*overflow-x: auto;*/
}

.main_seat_control_time {
  list-style: none;
  /*width: 100%;*/
  display: flex;
  /*display: grid;*/
  /*grid-template-columns:repeat(2, 1fr);*/
  /*grid-row-gap: 10px;*/
  /*grid-column-gap: 10px;*/
}

.main_seat_control_time_li {
  width: 200px;
  margin: 10px;
  color: #000;
  /*background-color: #2c3e50;*/
  padding: 10px;
  border: #282828 3px solid;
  border-radius: 10px;
}

.main_seat_control_time_li_click {
  outline: #ffb800 4px solid;
}

.main_seat_control_time_li_title {
  text-align: center;
  font-weight: bold;
}

.main_seat_control_time_li_main {
  display: flex;
  justify-content: end;
  margin-top: 5px;
}

.main_seat_control_time_li_state {
  text-align: center;
  border: #7c7c7c 2px solid;
  border-radius: 5px;
  padding: 2px;
  margin-right: 10px;
}

.main_seat_control_time_li_btn {
  font-weight: bold;
  float: right;
}

.main_seat_control_time_li_btn2 {
  font-weight: bold;
  margin: auto;
}

/*预约记录*/
.main_box_seat_history {
}

.main_seat_history_title {
  height: 30px;
  font-weight: bold;
  text-align: start;
  margin-bottom: 5px;
}

.main_seat_history_tb {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}

.main_seat_history_thead {
  background-color: rgb(244, 244, 244);
}

.main_seat_history_tbody {
  background-color: #fff;
}

.main_seat_history_th {
  line-height: 5vh;
  text-align: center;
  padding: 5px;
}
</style>