import service from "@/request/axios";

// main

// 定义座位管理 API
const url = 'api/v1/seat/';
// 将所有的请求方法封装到一个对象中
const Seat_api = {
    // 添加座位
    SaveSeat: (json) => service.post(`${url}`, json),
    // 座位列表
    ListSeat: (placeId,page,size) => service.get(`${url}list/${page}/${size}`, { params: { placeId } }),
    //查找座位
    FindSeat: (id) => service.get(`${url}${id}`),

};

// 导出对象
export default Seat_api;