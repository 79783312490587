import service from "@/request/axios";

// 定义场所管理 API
const url = '/api/v1/place/';
// 将所有的请求方法封装到一个对象中
const Place_api = {
    // 教室列表
    ListPlace: (keyword,page,size) => service.get(`${url}list/${page}/${size}`, { params: { keyword } }),

};

// 导出对象
export default Place_api;