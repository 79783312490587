import { createRouter, createWebHistory } from 'vue-router'
import AppMain from '../views/AppMain.vue'
import Seat from '../views/Seat.vue'
import Login from "@/views/Login.vue";
import Personal from "@/views/Personal.vue";
import PointsCenter from "@/views/PointsCenter.vue";
import PersonalModify from "@/views/PersonalModify.vue";
import ProblemFeedback from "@/views/ProblemFeedback.vue";
import UserSign from "@/views/UserSign.vue";
import UserCode from "@/views/UserCode.vue";

const routes = [
  {
    path: '/',
    name: 'appMain',
    component: AppMain
  },
  //  登录
  {
    path: "/login",
    name: "login",
    component: Login
  },
  //   座位预约
  {
    path: '/seat',
    name: 'seat',
    component: Seat
  },
  //   个人信息
  {
    path: '/personal',
    name: 'personal',
    component: Personal
  },
  //   用户签到签退 reservationId签退id state签到签退状态
  {
    path: '/userSign/:reservationId',
    name: 'userSign',
    component: UserSign
  },
  //   用户签到签退二维码 reservationId签退id state签到签退状态
  {
    path: '/userCode',
    name: 'userCode',
    component: UserCode
  },
  // 积分中心
  {
    path: "/points",
    name: "points",
    component: PointsCenter
  },
  // 个人信息修改
  {
    path: "/personalModify",
    name: "personalModify",
    component: PersonalModify
  },
  // 问题防窥
  {
    path: "/problemFeedback",
    name: "problemFeedback",
    component: ProblemFeedback
  }
]




const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  console.log(to.path)
  if (to.path === '/login' || to.path === "/") {
    next();
  } else {
    let token = localStorage.getItem('Authorization');

    if (token === null || token === '') {
      next('/login');
    } else {
      next();
    }
  }
});

export default router
