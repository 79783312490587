<template>
  <el-container class="box">
    <!-- 头部 -->
    <HeaderSecond></HeaderSecond>


    <!--  中部  -->
    <el-container class="center_container">

      <!--   主要内容   -->
      <el-main class="main">
        <el-row class="main_row">

          <!--   签退签到   -->
          <el-col class="UserSign_box">
            <div class="main_box_col">
              <div class="UserSign_title">
                <span v-if="status == 1">签到</span>
                <span v-if="status == 2">签退</span>
                <router-link to="/" class="UserSign_title_href">返回首页</router-link>
              </div>
              <div class="UserSign_show_box">
                <svg t="1718676572925" class="icon" viewBox="0 0 1024 1024" version="1.1"
                     xmlns="http://www.w3.org/2000/svg" p-id="1641" width="100" height="100">
                  <path
                      d="M511.994929 64.908336a447.259005 447.259005 0 0 1 174.015193 859.071974A447.259005 447.259005 0 0 1 337.989878 100.01969 444.216427 444.216427 0 0 1 511.994929 64.908336m0-64.908336C229.207563 0 0 229.207563 0 511.994929s229.207563 512.005071 511.994929 512.005071 512.005071-229.207563 512.005071-512.005071S794.772153 0 511.994929 0z"
                      fill="#333333" p-id="1642"></path>
                  <path d="M221.952028 496.293197l45.897293-45.896279 211.665071 211.664056-45.897293 45.897294z"
                        fill="#333333" p-id="1643"></path>
                  <path d="M390.60417 662.740484l370.59009-370.591104 45.897293 45.897293-370.591104 370.591104z"
                        fill="#333333" p-id="1644"></path>
                </svg>
                <span v-if="status == 1">签到成功</span>
                <span v-if="status == 2">签退成功</span>
              </div>
            </div>
          </el-col>
        </el-row>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>

import HeaderSecond from "@/components/HeaderSecond.vue";
import CheckRecord_api from "@/request/check_record";
import Reservation_api from "@/request/reservation";

export default {
  name: 'UserSign',
  components: {HeaderSecond},
  created() {
    this.reservationId = this.$route.params.reservationId;
  },
  data() {
    return {
      status:0
    };
  },
  methods: {
    // 获取状态信息
    get_status(){
      Reservation_api.Reservation(this.reservationId).then((res)=>{
        this.status = res.data.status;
        this.Check_on();
      }).catch((rep)=>{
        console.log(rep);
      })
    },

    // 签到或签退
    Check_on() {
      if(this.status == 1){
        CheckRecord_api.CheckIn(this.reservationId).then((res)=>{
          console.log(res);
        }).catch((rep)=>{
          console.log(rep);
        })
      }
      else if(this.status == 2){
        CheckRecord_api.CheckOut(this.reservationId).then((res)=>{
          console.log(res);
        }).catch((rep)=>{
          console.log(rep);
        })
      }
    }
  },
  mounted() {
    this.get_status()

  }
}
</script>

<style scoped>
* {
  marigin: 0;
  padding: 0;
}

.box {
  height: 100vh;
  display: flex;
  flex-direction: column;
}


/*中部*/
.center_container {
  background-color: rgb(229, 233, 240);
  height: 100%;
  marigin: 0;
  padding: 0;
}


/*主要内容*/
.main {
}

.main_row {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/*签退签到*/
.UserSign_box {
  min-width: 300px;
  width: 60%;
  background-color: #fff;
  padding: 20px;
  margin-top: 10px;
  font-weight: bold;
}

.UserSign_title {
  height: 30px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.UserSign_title_href {
  text-decoration: none;
  color: #000;
}

.UserSign_title_href:hover {
  text-decoration: none;
}

.UserSign_title_href:link {
  text-decoration: none;
}

.UserSign_title_href:active {
  text-decoration: none;
}

.UserSign_title_href:visited {
  text-decoration: none;
}

.UserSign_show_box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px;
}


</style>