<template>
  <el-container class="box">
    <!-- 头部 -->
    <HeaderSecond></HeaderSecond>


    <!--  中部  -->
    <el-container class="center_container">

      <!--   主要内容   -->
      <el-main class="main">
        <el-row class="main_row">

          <!--   反馈信息   -->
          <el-col class="Problem_box">
            <div class="main_box_col">
              <div class="Problem_title">
                <span>问题反馈提交</span>
                <router-link to="/" class="Problem_title_href">返回首页</router-link>
              </div>
              <div class="Problem_show_box">
                <el-input
                    v-model="Feedback_data.content"
                    :rows="16"
                    type="textarea"
                    placeholder="请输入问题"
                    class="problem_input"
                />
              </div>
              <el-button type="info" class="Problem_update  btn_p" @click="update_btn">提交反馈</el-button>
            </div>
          </el-col>

        </el-row>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>

import HeaderSecond from "@/components/HeaderSecond.vue";
import Feedback_api from "@/request/feedback";
import router from "@/router";

export default {
  name: 'ProblemFeedback',
  components: {HeaderSecond},
  data() {
    return {
      Feedback_data:{
        userId:localStorage.getItem('userId'),
        content:'',
      },
    };
  },
  methods: {
    // 保存数据
    save_data(){
      Feedback_api.SaveFeedback(this.Feedback_data).then((res)=>{
        alert('提交成功')
        console.log(res)
      }).catch((rep)=>{
        console.log(rep)
      })
    },

    // 提交按钮
    update_btn(){
      if(this.Feedback_data.content!=''){
        this.save_data()
        // router.push('/');
      }
    }
  },
  mounted() {
  }
}
</script>

<style scoped>
* {
  marigin: 0;
  padding: 0;
}

.box {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.btn_p{
  padding-left: 10px;
  padding-right: 10px;
}

/*中部*/
.center_container {
  background-color: rgb(229, 233, 240);
  height: 100%;
  marigin: 0;
  padding: 0;
  overflow: hidden;
}


/*主要内容*/
.main {
}

.main_row {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/*反馈信息*/
.Problem_box {
  min-width: 600px;
  width: 60%;
  background-color: #fff;
  padding: 20px;
  margin-top: 10px;
  font-weight: bold;
}

.Problem_title {
  height: 30px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.Problem_title_href{
  text-decoration: none;
  color: #000;
}

.Problem_title_href:hover{
  text-decoration: none;
}
.Problem_title_href:link{
  text-decoration: none;
}
.Problem_title_href:active{
  text-decoration: none;
}
.Problem_title_href:visited{
  text-decoration: none;
}

.Problem_show_box {
  display: flex;
  flex-direction: row;
}

.problem_input{
  width: 100%;
  border: 2px solid #000;
  border-radius: 5px;
}

.Problem_update{
  margin-top: 30px;
  font-weight: bold;
  float: right;
}

</style>