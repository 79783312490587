

<template>
<el-container class="box">
  <div class="login-form">
    <el-header>
      <h3>登录</h3>
    </el-header>
    <el-main>
      <el-form :model="ruleForm" :rules="rules" ref="ruleFormsss">
        <el-form-item prop="username">
          <el-input v-model="ruleForm.username" placeholder="账号"></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input type="password" v-model="ruleForm.password" placeholder="密码"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary"  @click="submitForm()" style="flex: 1">登 录</el-button>
        </el-form-item>
      </el-form>
    </el-main>

  </div>


</el-container>

</template>

<script>
import {
  ref,
  reactive,
  unref
} from "vue";
import Header from "@/components/Header.vue";
import axios from "axios";
import {mapMutations, useStore} from "vuex";
import router from '@/router'
import Auth_api from "@/request/auth";



export default {
  components: {Header},
  setup() {
    const ruleFormsss = ref(null);
    const store = useStore();
    let userToken = ""
    let userId = ""
    let isLogin = 0
    // 定义变量
    const ruleForm = reactive({
      username: '',
      password: '',
    })

    const rules = {
      username: [
        { required: true, message: '请输入用户名', trigger: 'blur' },
      ],
      password: [
        { required: true, message: '请输入密码', trigger: 'blur' },
      ]
    }

    const submitForm = async () => {
      const form = unref(ruleFormsss.value);
      if (!form) return
      try {
        await form.validate()
        const { username, password } = ruleForm

        Auth_api.login( {
          username: username,
          password: password
        })
            .then(function (res) {
              userToken =  res.data.param.token;
              userId = res.data.id
              isLogin = 1
              // 将token保存进vuex中
              store.commit("changeLogin",{ Authorization: userToken, userId, isLogin } );
              router.push("/seat")
            })
            .catch(function (error) {
              console.log(error)
            })

        // axios.post("/api/v1/auth/login", {
        //   username: username,
        //   password: password
        // })
        // .then(function (res) {
        //   userToken =  res.data.data.param.token;
        //   userId = res.data.data.id
        //   console.log(userId)
        //   console.log(res)
        //   // 将token保存进vuex中
        //   store.commit("changeLogin",{ Authorization: userToken, userId } );
        //   router.push("/seat")
        // })
        // .catch(function (error) {
        //   console.log(error)
        // })
      } catch (error) {
        console.log(error)
      }
    }
    return {
      ruleForm,
      rules,
      submitForm,
      ruleFormsss
    }
  }


}
</script>

<style scoped>

.box {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(to right, #fbc2eb, #a6c1ee);
}

.login-form {
  padding: 100px;
  text-align: center;
  background: inherit;
  border-radius: 18px;
  overflow: hidden;
}


</style>