<template>
  <el-container class="box">
    <!-- 头部 -->
    <HeaderSecond></HeaderSecond>

    <!--  中部  -->
    <el-container class="center_container">

      <!--   主要内容   -->
      <el-main class="main">
        <el-row class="main_row">

          <!--   个人信息   -->
          <el-col class="personal_box">
            <div class="main_box_col">
              <div class="personal_title">
                <span>个人信息修改</span>
                <router-link to="/personal" class="personal_title_href">返回上一页</router-link>
              </div>
              <div class="personal_show_box">
                <div class="personal_show_header">
                  <img :src="img" v-if="user_information.headUrl == '' || user_information.headUrl == null"
                       class="personal_show_img" alt="图片加载失败">
                  <img :src="user_information.headUrl"
                       v-if="user_information.headUrl != ''  && user_information.headUrl != null"
                       class="personal_show_img" alt="图片加载失败">


                  <input ref="fileInput" type="file" @change="onFileChange" style="display: none;"/>
                  <button class="personal_show_btn" @click="triggerFileInput">上传图片</button>

<!--                  <buton class="personal_show_btn" @click="">修改个人图片</buton>-->
                </div>
                <div class="personal_show_content">
                  <el-form class="personal_show_content_ul">
                    <el-form-item class="personal_show_content_li">
                      <div class="content-wrapper">
                        <span>用户名:</span>
                        <el-input placeholder="请输入用户名" v-model="user_information.username"
                                  class="personal_modeify_input"/>
                      </div>
                    </el-form-item>
                    <el-form-item class="personal_show_content_li">
                      <div class="content-wrapper">
                        <span>密码:</span>
                        <el-input placeholder="请输入密码" v-model="user_information.password"
                                  class="personal_modeify_input"/>
                      </div>
                    </el-form-item>
                    <el-form-item class="personal_show_content_li">
                      <div class="content-wrapper">
                        <span>姓名:</span>
                        <el-input placeholder="请输入姓名" v-model="user_information.name"
                                  class="personal_modeify_input"/>
                      </div>
                    </el-form-item>
                    <el-form-item class="personal_show_content_li">
                      <div class="content-wrapper">
                        <span>性别:</span>
                        <el-select v-model="user_information.sex" placeholder="请选择性别"
                                   class="personal_modeify_input">
                          <el-option label="男" value="0"/>
                          <el-option label="女" value="1"/>
                          <el-option label="其他" value="2"/>
                        </el-select>
                      </div>
                    </el-form-item>
                    <el-form-item class="personal_show_content_li">
                      <div class="content-wrapper">
                        <span>系部:</span>
                        <el-input placeholder="请输入系部" v-model="user_information.department"
                                  class="personal_modeify_input"/>
                      </div>
                    </el-form-item>
                    <el-form-item class="personal_show_content_li">
                      <div class="content-wrapper">
                        <span>专业:</span>
                        <el-input placeholder="请输入专业" v-model="user_information.major"
                                  class="personal_modeify_input"/>
                      </div>
                    </el-form-item>
                    <el-form-item class="personal_show_content_li">
                      <div class="content-wrapper">
                        <span>年级:</span>
                        <el-input type="number" placeholder="请输入年级" v-model="user_information.grade"
                                  class="personal_modeify_input"/>
                      </div>
                    </el-form-item>
                    <el-form-item class="personal_show_content_li">
                      <div class="content-wrapper">
                        <span>班级:</span>
                        <el-input placeholder="请输入班级" v-model="user_information.classroom"
                                  class="personal_modeify_input"/>
                      </div>
                    </el-form-item>
                    <el-form-item class="personal_show_content_li">
                      <div class="content-wrapper">
                        <span>出生日期:</span>
                        <el-input type="date" placeholder="请输入出生日期" v-model="user_information.birthday"
                                  class="personal_modeify_input"/>
                      </div>
                    </el-form-item>
                    <el-form-item class="personal_show_content_li">
                      <div class="content-wrapper">
                        <span>入校时间:</span>
                        <el-input type="date" placeholder="请输入入校时间" v-model="user_information.entranceTime"
                                  class="personal_modeify_input"/>
                      </div>
                    </el-form-item>
                    <el-form-item class="personal_show_content_li">
                      <div class="content-wrapper">
                        <span>个人邮箱:</span>
                        <el-input placeholder="请输入邮箱" v-model="user_information.email"
                                  class="personal_modeify_input"/>
                      </div>
                    </el-form-item>
                    <el-form-item class="personal_show_content_li">
                      <div class="content-wrapper">
                        <span>个人手机:</span>
                        <el-input type="number" placeholder="请输入个人手机" v-model="user_information.phone"
                                  class="personal_modeify_input"/>
                      </div>
                    </el-form-item>
                  </el-form>
                </div>
              </div>
              <el-button type="info" class="personal_update btn_p" @click="update_User">提交更改</el-button>
            </div>
          </el-col>
        </el-row>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>

import HeaderSecond from "@/components/HeaderSecond.vue";
import User_api from "@/request/user";
import router from "@/router";

export default {
  name: 'PersonalModify',
  components: {HeaderSecond},
  data() {
    return {
      img: require("../assets/person.png"),
      userid: localStorage.getItem('userId'),
      user_information: {
        "username": "",
        "password": "",
        "name": '',
        "sex": null,
        "department": '',
        "major": '',
        "grade": null,
        "classroom": '',
        "birthday": '',
        "entranceTime": '',
        "email": '',
        "phone": '',
      },
      // 图片上传
      selectedFile:null,
    };
  },
  methods: {
    triggerFileInput() {
      this.$refs.fileInput.click(); // 使用$refs访问DOM元素并触发点击事件
    },

    // 图片上传
    onFileChange(e) {
      // 获取选中的文件
      const file = e.target.files[0];

      if (!file) return;
      // 将文件存储在data中，以便后续上传
      this.selectedFile = file;
      this.uploadImage();

    },
    async uploadImage() {
      try {
        // 创建formData对象
        const formData = new FormData();
        // 将文件添加到formData中，'image'是服务器端接收的字段名
        formData.append('multipartFile', this.selectedFile);


        // 发送POST请求，这里假设服务器接口为'/api/upload'
        User_api.uploadPic(this.userid,formData).then((res)=>{
          this.get_User()

          console.log(res);
        }).catch((rep)=>{
          console.log(rep);
        })

      } catch (error) {
        console.error('图片上传失败:', error);
      }
    },


    // 获取用户信息
    get_User() {
      User_api.FindUser(this.userid).then((res) => {

        this.user_information = res.data
        this.user_information.sex = this.user_information.sex.toString()

        console.log(res)
      }).catch((rep) => {
        console.log(rep)
      })
    },





    //修改用户信息
    update_User() {
      this.user_information.id = this.userid

      this.user_information.sex = parseInt(this.user_information.sex)


      if('headUrl' in this.user_information){
        delete this.user_information.headUrl
      }

      if(this.user_information.password == ''){
        delete this.user_information.password
      }

      User_api.UpdateUser(this.user_information).then((res) => {
        this.get_User()
        alert('修改成功')
      }).catch((rep) => {
        console.log(rep)

      })


      // router.push('/personal');
    },
  },
  mounted() {
    this.get_User()
  }
}
</script>

<style scoped>
* {
  marigin: 0;
  padding: 0;
}

.box {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.btn_p{
  padding-left: 10px;
  padding-right: 10px;
}


/*中部*/
.center_container {
  background-color: rgb(229, 233, 240);
  height: 100%;
  marigin: 0;
  padding: 0;
}


/*主要内容*/
.main {
}

.main_row {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/*个人信息*/
.personal_box {
  min-width: 1000px;
  background-color: #fff;
  padding: 20px;
  margin-top: 10px;
  font-weight: bold;
}

.personal_title {
  height: 30px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.personal_title_href {
  text-decoration: none;
  color: #000;
}

.personal_title_href:hover {
  text-decoration: none;
}

.personal_title_href:link {
  text-decoration: none;
}

.personal_title_href:active {
  text-decoration: none;
}

.personal_title_href:visited {
  text-decoration: none;
}

.personal_show_box {
  display: flex;
  flex-direction: row;
}

.personal_show_header {
  display: flex;
  flex-direction: column;
}

/*图片信息*/
.personal_show_img {
  background-color: #808080;
  padding: 10px;
  width: 90px;
  height: 90px;
}

.personal_show_btn {
  margin-top: 10px;
  background-color: rgb(129, 129, 129);
  padding: 10px;
  font-size: 15px;
  color: #fff;
}

/*个人信息*/

.personal_show_content {
  width: 100%;
}

.personal_show_content_ul {
  list-style: none;
  display: grid;
  grid-template-columns:repeat(2, 1fr);
  grid-row-gap: 10px;
  grid-column-gap: 10px;
  margin: 0px;
}


.personal_show_content_li {
  width: 80%;
  padding: 5px;
  text-align: start;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.content-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.personal_modeify_input {
  width: 65%;
  margin-left: 10px;
  border: #000 1px solid;
  border-radius: 5px;
}

/*提交按钮*/
.personal_update {
  margin-top: 30px;
  font-weight: bold;
  float: right;
}

</style>