<template>
  <el-container class="box">
    <!-- 头部 -->
    <HeaderSecond></HeaderSecond>


    <!--  中部  -->
    <el-container class="center_container">

      <!--   主要内容   -->
      <el-main class="main">
        <el-row class="main_row">

          <!--   签退签到二维码   -->
          <el-col class="UserCode_box">
            <div class="main_box_col">
              <div class="UserCode_title">
                <span>签到或签退</span>
                <router-link to="/" class="UserCode_title_href">返回首页</router-link>
              </div>
              <div class="UserCode_show_box">
                <img :src="displayImage" alt="二维码" class="UserCode_show_img"/>
              </div>
            </div>
          </el-col>
        </el-row>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>

import HeaderSecond from "@/components/HeaderSecond.vue";
import Reservation_api from "@/request/reservation";

export default {
  name: 'UserCode',
  components: {HeaderSecond},
  created() {
    this.reservationId = this.$route.query.reservationId;},
  computed: {
    // 计算属性确保每次base64ImageData变化时，自动更新图片源
    displayImage() {
      // 确保Base64字符串是完整的，包括"data:image/[format];base64,"前缀
      // 如果从服务器获取的数据已经完整，此步骤可能不需要
      const prefix = this.qrcode.startsWith('data:') ? '' : 'data:image/jpeg;base64,';
      return prefix + this.qrcode;
    },
  },
  data() {
    return {
      // 二维码
      qrcode:'',
    };
  },
  methods: {
    getCode() {
      Reservation_api.Qrcode(this.reservationId).then((res)=>{
        this.qrcode = res.data
      }).catch((rep)=>{
        console.log(rep)
      })
    }
  },
  mounted() {
    this.getCode()

  }
}
</script>

<style scoped>
* {
  marigin: 0;
  padding: 0;
}

.box {
  height: 100vh;
  display: flex;
  flex-direction: column;
}


/*中部*/
.center_container {
  background-color: rgb(229, 233, 240);
  height: 100%;
  marigin: 0;
  padding: 0;
}


/*主要内容*/
.main {
}

.main_row {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/*签退签到二维码*/
.UserCode_box {
  min-width: 600px;
  width: 60%;
  background-color: #fff;
  padding: 20px;
  margin-top: 10px;
  font-weight: bold;
}

.UserCode_title {
  height: 30px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.UserCode_title_href {
  text-decoration: none;
  color: #000;
}

.UserCode_title_href:hover {
  text-decoration: none;
}

.UserCode_title_href:link {
  text-decoration: none;
}

.UserCode_title_href:active {
  text-decoration: none;
}

.UserCode_title_href:visited {
  text-decoration: none;
}

.UserCode_show_box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px;
}

.UserCode_show_img{
  width: 40%;
}

</style>