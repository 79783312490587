import service from "@/request/axios";

// main

// 定义用户管理 API
const url = '/api/v1/user/';
// 将所有的请求方法封装到一个对象中
const User_api = {
    //查找用户
    FindUser: (id) => service.get(`${url}${id}`),
    //修改信息
    UpdateUser: (json) => service.put(`${url}update`,json),
    //查询预约历史记录
    listReservationUser:(page,size,userId)=>service.get(`${url}listReservation/${page}/${size}/${userId}`),
    //上传用户图像
    uploadPic: (id, file) => service.post(`${url}uploadPic/${id}`, file, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    }),


};

// 导出对象
export default User_api;