import service from "@/request/axios";

// main

// 定义预约信息管理 API
const url = '/api/v1/reservation/';
// 将所有的请求方法封装到一个对象中
const Reservation_api = {
    // 预约信息列表
    ListReservation: (placeId,appointmentSlotsId,page,size) => service.get(`${url}list/${page}/${size}/${placeId}/${appointmentSlotsId}`),
    // 预约信息
    Reservation: (id) => service.get(`${url}${id}`),
    // 预约二维码
    Qrcode: (id) => service.get(`${url}qrcode/${id}`),
    // 预约
    Reserve: (json) => service.post(`${url}reserve`, json),
    // 取消预约
    ReserveCancel: (id) => service.put(`${url}${id}`),



};

// 导出对象
export default Reservation_api;