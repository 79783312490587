<template>
<!--  <div>-->
    <el-header class="header">
      <!--   导航栏   -->
      <el-menu
          class="el-menu-demo"
          mode="horizontal"
          background-color="#2c3e50"
          text-color="#fff"
          active-text-color="#ffd04b"
          router
          @select="handleSelect"
      >
        <el-menu-item class="menu_li" index="/">首页</el-menu-item>
        <el-menu-item class="menu_li" index="/seat">座位预约</el-menu-item>
        <el-menu-item class="menu_li" index="/personal">个人信息</el-menu-item>
        <el-menu-item class="menu_li" index="/points">积分中心</el-menu-item>
        <el-menu-item class="menu_li" index="/problemFeedback">问题反馈</el-menu-item>
      </el-menu>
    </el-header>
<!--  </div>-->
</template>

<script>

export default {
  name: 'HeaderSecond',
  components: {
  },
  data() {
    return {};
  },
  methods: {
    handleSelect(key, keyPath) {
      this.$router.push(key);
    }
  },
  mounted() {
  }
}
</script>

<style scoped>
* {
  marigin: 0;
  padding: 0;
}

/*头部*/
.header {
  /*width: 100%;*/
  marigin: 0;
  padding: 0;
  background: #2c3e50;
}

.el-menu-demo {
  font-weight: bold;
}

.menu_li{
  padding-left: 10px;
  padding-right: 10px;
}
</style>