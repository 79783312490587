<template>
  <el-container>
    <el-header>
      <!--  <el-header style="margin: 10px">-->
      <el-row style="width: 100%;display: flex;flex-direction: row;align-items:center;justify-content: space-between">
        <el-col :span="5" style="display: flex;overflow: hidden;">
          <el-image style="width: 50px; height: 50px" :src="logo" :fit="'fill'"/>
          <el-text id="header-text" size="large">学习用个人座位信息管理</el-text>
        </el-col>

        <el-col :span="15">
          <el-menu
              class="el-menu-demo"
              mode="horizontal"
              active-text-color="#ffd04b"
              router
              @select="handleSelect"
          >
            <el-menu-item index="/">首页</el-menu-item>
            <el-menu-item index="/seat">座位预约</el-menu-item>
            <el-menu-item index="/personal">个人信息</el-menu-item>
            <el-menu-item index="/points">积分中心</el-menu-item>
            <el-menu-item index="/problemFeedback">问题反馈</el-menu-item>
          </el-menu>
          <!--        <el-menu-->
          <!--            :default-active="activeIndex"-->
          <!--            :ellipsis="false"-->
          <!--            class="el-menu-demo"-->
          <!--            mode="horizontal"-->
          <!--            @select="handleSelect"-->
          <!--            :router="true"-->
          <!--        >-->
          <!--          <el-menu-item index="/">首页</el-menu-item>-->
          <!--&lt;!&ndash;          <el-menu-item index="/login">座位预约</el-menu-item>&ndash;&gt;-->
          <!--          <el-menu-item index="/seat">座位预约</el-menu-item>-->
          <!--          <el-menu-item index="/personal">个人信息</el-menu-item>-->
          <!--          <el-menu-item index="/points">积分中心</el-menu-item>-->
          <!--          <el-menu-item index="/problemFeedback">问题反馈</el-menu-item>-->
          <!--        </el-menu>-->
        </el-col>


        <el-col :span="4" style="display: flex;justify-content: end;align-items: center;" v-if="isShow">
          <el-dropdown>
                  <span class="el-dropdown-link">
                  <el-image style="width: 30px; height: 30px" :src="profile" :fit="'fill'"/>
                    <el-image style="width: 30px; height: 20px" :src="dropdown" :fit="'fill'"/>
                  </span>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item index="/personal">个人中心</el-dropdown-item>
                <el-dropdown-item @click="logout">退出登录</el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </el-col>
      </el-row>
    </el-header>
  </el-container>
</template>

<script setup>
import {watch, onMounted, ref, computed} from "vue";
import {useRoute, useRouter} from 'vue-router';
import {useStore} from "vuex";

const logo = require("../assets/studyroom-logo.png")
const profile = require("../assets/people.png")
const dropdown = require("../assets/dropdown.png")


// 使用 useRoute 和 useRouter 获取路由实例
const route = useRoute();
const router = useRouter();

// 使用useStore获取store实例
const store = useStore();

// 获取登录状态
const isLogin = computed(() => store.getters.getIsLogin);

// 通过登录状态自动判断是否显示
const isShow = computed({
  get() {
    return isLogin.value === 1;
  },
  set(value) {
    // 如果需要，可以在这里添加设置 isShow 的逻辑，但通常计算属性的 set 方法较少使用
  }
});



// 定义响应式状态
const activeIndex = ref(route.path);

// 监听路由变化
watch(() => route.path, (newPath) => {
  activeIndex.value = newPath;
});

// 定义 setCurrentRoute 函数
function setCurrentRoute() {
  activeIndex.value = route.path; // 通过这个函数可以监听到当前路由状态并激活当前菜单
}

// 在组件挂载时调用 setCurrentRoute 以设置初始路由状态
onMounted(() => {
  setCurrentRoute();
});

// 定义 handleSelect 函数
function handleSelect(data) {
  router.push(data);
}

function logout() {
  localStorage.removeItem('Authorization');
  localStorage.removeItem("userId");
  router.push('/login');
}
</script>


<style scoped>

@media screen and (max-width: 768px) {
  #header-text{
    display: none;
  }
}

#header-text {
  margin-left: 15px;
}

.example-showcase .el-dropdown + .el-dropdown {
  margin-left: 15px;
}

.el-dropdown-link {
  cursor: pointer;
  color: var(--el-color-primary);
  display: flex;
  outline: none;
  align-items: center;
}

.el-dropdown-link:hover {
  border: none;
  outline: none;
}

.el-menu--horizontal.el-menu {
  border: none;
}

.link-text {
  text-decoration: none;
  color: black;
}

.exact-active-link {
  border-bottom: antiquewhite solid 1px;
  color: red;
  background-color: blue;
}
</style>