<template>
  <el-container style="height: 100vh;display: flex;flex-direction: column;">
    <HeaderSecond/>
    <el-container class="center_container">
      <el-main>
        <el-row style="justify-content: center">
          <el-col :span="12" class="box">
            <el-col>
              <h1>积分中心</h1>
            </el-col>
            <el-col style="margin-top: 20px">
              <h1 style="font-size: 40px">{{ point }}</h1>
            </el-col>
            <el-col>
              <el-text>你的积分</el-text>
            </el-col>
          </el-col>
        </el-row>
        <el-row style="justify-content: center; margin-top: 40px">
          <el-col :span="12" class="box">
            <el-col>
              <el-table highlight-current-row v-loading="loading" :data="tableData" style="width: 100%">
                <el-table-column prop="changeTime" label="时间" width="180"/>
                <el-table-column prop="scoreChange" label="扣分情况" width="180"/>
                <el-table-column prop="reason" label="详细"/>
              </el-table>
              <el-pagination style="display: flex; justify-content: center;margin-top: 10px" background
                             layout="prev, pager, next" :total="total"
                             v-model:current-page="page"
                             :default-page-size="size"
                             @change="fetchData"/>
            </el-col>

          </el-col>
        </el-row>
      </el-main>
    </el-container>
  </el-container>

</template>

<script>
import axios from "axios";
import {computed, ref} from "vue"
import {useStore} from 'vuex';
import Points_api from "../request/points"
import HeaderSecond from "@/components/HeaderSecond.vue";

export default {
  components: {HeaderSecond},
  setup() {
    const circleUrl = require("../assets/people.png")
    const point = ref(28)
    // 使用useStore获取store实例
    const store = useStore();

    // 从getter中获取userId
    const userId = computed(() => store.getters.getUserId);

    async function pointNum() {
      try {
        const response = await axios.get(`/api/v1/user/${userId.value}`);
        point.value = response.data.data.creditScore;
      } catch (error) {
        console.error('获取数据失败', error);
      }
    }

    // function pointNum() {
    //
    // }

    pointNum()

    const debounce = (fn, delay) => {
      let timer;
      return (...args) => {
        if (timer) {
          clearTimeout(timer);
        }
        timer = setTimeout(() => {
          fn(...args);
        }, delay);
      };
    };
    const resizeObserver = window.ResizeObserver;
    window.ResizeObserver = class ResizeObserver extends resizeObserver {
      constructor(callback) {
        callback = debounce(callback, 200);
        super(callback);
      }
    };

    const page = ref(1)
    const size = ref(7)
    const total = ref(0)
    const tableData = ref([])
    const loading = ref(true)

    function fetchData() {
      loading.value = true
      Points_api.ListPointsById(page.value, size.value, userId.value)
          .then((res) => {
            console.log(res)
            total.value = res.data.total
            tableData.value = res.data.records
            loading.value = false
          })
          .catch((error) => {
            console.log(error)
            loading.value = false
          })
    }

    fetchData();

    return {
      circleUrl,
      point,
      tableData,
      total,
      page,
      size,
      loading,
      fetchData
    }
  }
}

</script>

<style scoped>
.box {
  background-color: white;
  border-radius: 4px;
  padding: 20px;
}


/*中部*/
.center_container {
  background-color: rgb(229, 233, 240);
  height: 100%;
  marigin: 0;
  padding: 0;
  overflow: hidden;
}
</style>