import service from "@/request/axios";

// 定义反馈管理 API
const url = '/api/v1/feedback/';
// 将所有的请求方法封装到一个对象中
const Feedback_api = {
    //提交反馈
    SaveFeedback: (json) => service.post(`${url}save`,json),
};

// 导出对象
export default Feedback_api;