import service from "@/request/axios";

// main

// 定义签入签出管理 API
const url = '/api/v1/checkInCheckOutRecord/';
// 将所有的请求方法封装到一个对象中
const CheckRecord_api = {
    //签到
    CheckIn: (reservationId) => service.get(`${url}checkIn/${reservationId}`),
    //签退
    CheckOut: (reservationId) => service.get(`${url}checkOut/${reservationId}`),


};

// 导出对象
export default CheckRecord_api;