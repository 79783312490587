<template>
  <Header style="width: 100%;"/>
  <el-container style="justify-content: center;">
    <el-main style="flex-direction: column">

      <el-row class="main_box">
        <el-col :span="16" :xs="24">
          <div class="block text-center">

            <el-carousel trigger="click" :height="screenwidth">
              <el-carousel-item v-for="(item,index) in imgList" :key="index">
                <el-image :src="item.link" alt="" srcset="" style="width: 100%; height:100%"/>
              </el-carousel-item>
            </el-carousel>
          </div>
        </el-col>
        <el-col :span="7" :xs="24" class="banner_m" style="box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;">
          <el-timeline style="width: 90%; overflow: auto;height: 500px; padding: 0 10px;" class="hide-scrollbar" hide-timestamp="false" v-infinite-scroll="loadMore">
            <h1 style="font-size: 30px;margin: 20px">公告内容</h1>
            <div infinite-scroll-disabled="loading" infinite-scroll-distance="10">
              <el-timeline-item
                  v-for="(item, index) in timelineData"
                  :key="index"
                  :timestamp="item.updateTime"
              >
                <el-card>
                  <h4>{{ item.title }}</h4>
                  <p>{{ item.content }}</p>
                </el-card>

              </el-timeline-item>

              <div v-if="loading" style="margin: 20px">Loading...</div>
            </div>
          </el-timeline>
        </el-col>
      </el-row>
      <el-row style="justify-content: space-between;">
        <el-col :span="24" style="margin: 20px 10px 10px 10px">
          <h3 style="text-align: center; font-size: 30px ">场所信息</h3>
        </el-col>
        <el-col :span="4" v-loading="loading" v-for="(item,index) in studyRoomCardList" :key="index"
                style="box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;margin-top: 20px">
          <el-card style="max-width: 480px; height: 100%" :body-style="{ padding: '0px' }" shadow="hover">
            <img
                src="../assets/studyroom1.jpeg"
                style="width: 100%; min-height: 200px;object-fit: cover;"
            />
            <div slot="footer" class="el-card__footer">{{ item.placeName }}</div>
          </el-card>
        </el-col>
      </el-row>
    </el-main>

  </el-container>
</template>

<script setup>

import Header from "@/components/Header.vue";
import { ref } from "vue"
import axios from "axios";
import Announcement_api from "@/request/announcement";

const imgList = [
  {
    link: require("../assets/studyroom1.jpeg"),
    text: "1号自习室"
  },
  {
    link: require("../assets/studyroom2.jpg"),
    text: "2号自习室"
  },
  {
    link: require("../assets/studyroom3.png"),
    text: "3号自习室"
  },
  {
    link: require("../assets/studyroom4.jpeg"),
    text: "4号自习室"
  }
]
const studyRoomCardList = ref([])
const rooms = ref(1)
const size = ref(5)

async function getStudyRoom() {
  try {
    const response = await axios.get(`/api/v1/place/list/${rooms.value}/${size.value}`);
    studyRoomCardList.value = response.data.data.records;
  } catch (error) {
    console.error('获取数据失败', error);
  }
}
getStudyRoom()

let screenwidth = "500px";
window.onresize = () => {
  if (document.documentElement.clientWidth > 960) {
    screenwidth = (document.documentElement.clientWidth * 668) / 1620 + "px";
  }
};

const timelineData = ref([])
const loading = ref(false)
const page = ref(1)
const rows = ref(4)

const loadMore = () => {
  if (loading.value) return;
  loading.value = true;

  // 调用接口获取更多数据
  Announcement_api.ListAnnouncement(page.value,rows.value).then(res => {
    console.log(res)
    timelineData.value = [...timelineData.value, ...res.data.records];
    timelineData.value.sort((a, b) => b.updateTime - a.updateTime);
    console.log(res)
    console.log(timelineData.value)
    loading.value = false;
  }).catch(error => {
    // 处理错误
    console.error('加载更多数据时出错:', error);
    loading.value = false;
  });
  // axios.get(`/api/v1/announcement/list/${page.value}/${rows.value}`).then(res => {
  //   timelineData.value = [...timelineData.value, ...res.data.data.records];
  //   timelineData.value.sort((a, b) => b.updateTime - a.updateTime);
  //   console.log(res)
  //   console.log(timelineData.value)
  //   loading.value = false;
  // }).catch(error => {
  //   // 处理错误
  //   console.error('加载更多数据时出错:', error);
  //   loading.value = false;
  // });
  // page.value = page.value + 1
};
loadMore()


</script>

<style scoped>
.demonstration {
  color: var(--el-text-color-secondary);
}

.el-carousel__item h3 {
  color: #475669;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
  text-align: center;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}

.el-card__footer {
  border: none !important;
  padding: 12px;
}


/*自适应配置*/
.main_box{
  flex-direction: row;
  justify-content: space-between;
}

@media screen and (max-width: 768px) {
  .main_box{
    flex-direction: column;
  }
  .banner_m{
    margin-top:20px;
  }
}

/* 隐藏滚动条但保持滚动功能 */
.hide-scrollbar::-webkit-scrollbar {
  display: none; /* 隐藏Webkit内核浏览器的滚动条 */
}

.hide-scrollbar {
  -ms-overflow-style: none;  /* IE 和 Edge */
  scrollbar-width: none;  /* Firefox */
  overflow-y: scroll; /* 保持滚动功能 */
}

</style>
